import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { H1 } from '@allthingswww/client-act-shared';

import Layout from '../components/Layout';
import titleIcon from '../../static/images/spadeIllustration.svg';
import Truck from '../../static/images/truckIllustration.svg';
import Birds from '../../static/images/birdsIllustration.svg';

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
`;

const StyledRightContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0rem 1rem;

  & > p {
    font-weight: 600;
    font-size: 24px;
  }

  & > h1 {
    font-size: 100px;
  }
`;

export const PageNotFoundPage = ({ data, location }) => {
  const { frontmatter } = data.page;
  const { title, subtitle, featuredImage } = frontmatter;

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      headerBackGroundImage={featuredImage}
      condensed
      icon={titleIcon}
      meta={{ data, location }}
    >
      <StyledContainer>
        <img src={Truck} alt="Truck illustration" width="40%" />
        <StyledRightContainer>
          <H1>That page is missing</H1>
          <p>
            We’re not quite sure what went wrong. You can go back, or try
            another page.
          </p>
        </StyledRightContainer>
        <img src={Birds} alt="Birds illustration" width="5%" />
      </StyledContainer>
    </Layout>
  );
};

export default PageNotFoundPage;

export const pageQuery = graphql`
  query PageNotFoundPageMdx($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`;
